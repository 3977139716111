import React, { ComponentType, PropsWithChildren, Suspense } from 'react';

import { LoadingPlaceholder } from '@grafana/ui';

export function withSuspense<T>(Component: ComponentType<T>) {
  return function SuspendedComponent(props: PropsWithChildren<T>) {
    return (
      <Suspense fallback={<LoadingPlaceholder text="Loading" />}>
        <Component {...props} />
      </Suspense>
    );
  };
}
