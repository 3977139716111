import {
  AbsoluteTimeRange,
  dateTime,
  DateTime,
  dateTimeFormatISO,
  isDateTime,
  rangeUtil,
  RawTimeRange,
  TimeRange,
} from '@grafana/data';
import { locationService } from '@grafana/runtime';
import { TimeZone } from '@grafana/schema';

import { isDate, isString } from 'lodash';

import { SerializableTimeRange } from 'types';

type AnyTimeRange = AbsoluteTimeRange | SerializableTimeRange | TimeRange;

export function toSerializableTimeRange(range: AnyTimeRange, timeZone: TimeZone): SerializableTimeRange {
  if (isSerializedTimeRange(range)) {
    return range;
  }
  const { from, to } = toTimeRange(range, timeZone);
  return { from: dateTimeFormatISO(from), to: dateTimeFormatISO(to) };
}

function lengthOfTimeRange(range: TimeRange): number {
  return range.to.valueOf() - range.from.valueOf();
}

export function getZoomedTimeRange(range: TimeRange, factor: number): TimeRange {
  const timespan = lengthOfTimeRange(range);
  const center = range.to.valueOf() - timespan / 2;

  const to = dateTime(center + (timespan * factor) / 2);
  const from = dateTime(center - (timespan * factor) / 2);

  return { from, to, raw: { from: dateTimeFormatISO(from), to: dateTimeFormatISO(to) } };
}

export function toTimeRange(range: AnyTimeRange, timeZone: TimeZone): TimeRange {
  if (isTimeRange(range)) {
    return range;
  }
  if (isSerializedTimeRange(range)) {
    return rangeUtil.convertRawToRange(range, timeZone);
  }
  return {
    from: dateTime(range.from),
    to: dateTime(range.to),
    raw: { from: dateTimeFormatISO(range.from), to: dateTimeFormatISO(range.to) },
  };
}

function isTimeRange(range: AnyTimeRange): range is TimeRange {
  return typeof (range as TimeRange)?.raw === 'object' && !isString(range.from) && !isString(range.to);
}

function isSerializedTimeRange(range: AnyTimeRange): range is SerializableTimeRange {
  return isString(range.from) && isString(range.to);
}

export function defaultCreateJobTimeRange(): SerializableTimeRange {
  const currentSearch = locationService.getSearchObject() ?? {};
  return {
    from: typeof currentSearch.from === 'string' ? currentSearch.from : 'now-1w',
    to: typeof currentSearch.to === 'string' ? currentSearch.to : 'now',
  };
}

export function defaultEditJobTimeRange(): SerializableTimeRange {
  return defaultCreateJobTimeRange();
}

export function defaultViewJobTimeRange(): SerializableTimeRange {
  const currentSearch = locationService.getSearchObject() ?? {};
  return {
    from: typeof currentSearch.from === 'string' ? currentSearch.from : 'now/1w',
    to: typeof currentSearch.to === 'string' ? currentSearch.to : 'now/1w',
  };
}

export function timeRangeToUtc(timeRange: TimeRange): SerializableTimeRange {
  const utcFrom = timeRange.from.utc().toISOString();
  const utcTo = timeRange.to.utc().toISOString();

  return {
    from: utcFrom.substring(0, utcFrom.indexOf('.')),
    to: utcTo.substring(0, utcTo.indexOf('.')),
  };
}

export function getMutableTimeRange(raw: RawTimeRange): TimeRange {
  // We are cloning the date time values due to a bug being fixed in this PR.
  // https://github.com/grafana/grafana/pull/77238
  return rangeUtil.convertRawToRange({
    from: cloneIfDateTime(raw.from),
    to: cloneIfDateTime(raw.to),
  });
}

function cloneIfDateTime(value: DateTime | string): DateTime | string {
  if (isDateTimeWithInput(value)) {
    return dateTime(value._i);
  }
  return value;
}

function isDateTimeWithInput(value: object | string): value is { _i: string | number | Date } {
  if (!isDateTime(value) || !('_i' in value)) {
    return false;
  }
  return typeof value._i === 'string' || typeof value._i === 'number' || isDate(value._i);
}
