import { SyntheticEvent } from 'react';

// Wrapper to turn useForm handleSubmit function into a response that satisfies typescript
export function onFormSubmit<T>(promise: (event: SyntheticEvent) => Promise<T>) {
  return (event: SyntheticEvent): void => {
    if (promise !== undefined && promise !== null) {
      promise(event).catch(() => null);
    }
  };
}

// handler wrapper
export function onHandler<T>(promise: () => Promise<T>) {
  return (): void => {
    if (promise !== undefined && promise !== null) {
      promise().catch(() => null);
    }
  };
}
