import { lazy } from 'react';

import { AppPlugin } from '@grafana/data';

import { MlAppSettings } from 'types';
import { withSuspense } from 'utils/utils.lazy';

import { configureAppExtensions } from './extensions';

const LazyApp = lazy(() => import('./App'));
const App = withSuspense(LazyApp);

export const plugin = new AppPlugin<MlAppSettings>().setRootPage(App);
configureAppExtensions(plugin);
