import * as C from 'consts';
import { TimeUnit } from 'types';

export function timeUnitToSeconds(time: number | string | undefined, unit: TimeUnit): number | undefined {
  const numTime = parseFloat((time ?? '').toString());
  if (isNaN(numTime)) {
    return undefined;
  }

  switch (unit) {
    case TimeUnit.Minutes:
      return numTime * C.ONE_MINUTE_IN_SECONDS;
    case TimeUnit.Hours:
      return numTime * C.ONE_HOUR_IN_SECONDS;
    case TimeUnit.Days:
      return numTime * C.ONE_DAY_IN_SECONDS;
    case TimeUnit.Years:
      return numTime * C.ONE_YEAR_IN_SECONDS;
    default:
      throw Error(`Unrecognised TimeUnit: ${unit as string}`);
  }
}

function getTimeUnit(valueInSeconds: number): TimeUnit {
  if (valueInSeconds >= C.ONE_YEAR_IN_SECONDS) {
    return TimeUnit.Years;
  } else if (valueInSeconds >= C.ONE_DAY_IN_SECONDS) {
    return TimeUnit.Days;
  } else if (valueInSeconds >= C.ONE_HOUR_IN_SECONDS) {
    return TimeUnit.Hours;
  } else {
    return TimeUnit.Minutes;
  }
}

function getValueInTimeUnit(valueInSeconds: number, unit: TimeUnit): number {
  switch (unit) {
    case TimeUnit.Minutes:
      return valueInSeconds / C.ONE_MINUTE_IN_SECONDS;
    case TimeUnit.Hours:
      return valueInSeconds / C.ONE_HOUR_IN_SECONDS;
    case TimeUnit.Days:
      return valueInSeconds / C.ONE_DAY_IN_SECONDS;
    case TimeUnit.Years:
      return valueInSeconds / C.ONE_YEAR_IN_SECONDS;
    default:
      throw Error(`Unrecognised TimeUnit: ${unit as string}`);
  }
}

export function secondsToTimeUnit(value: number): [number, TimeUnit] {
  const unit = getTimeUnit(value);
  const valueInSeconds = getValueInTimeUnit(value, unit);
  return [valueInSeconds, unit];
}
