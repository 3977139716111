import { DataFrame } from '@grafana/data';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Label, LabelInput, MatchType } from 'types';

interface FilterByLabels {
  [key: string]: LabelInput[];
}

// Transformation which filters DataFrames to only include those with a specific set of labels.
//
// The config is a map from refId to a map of labels to values. If a frame has a refId which is not
// in the config, it will be passed through unchanged.
// If a frame has labels that are in the config, but the values do not match, it will be filtered out.
// If there are labels in the config that are not present in the frame, the label will be ignored.
export const filterByLabelsTransformation = (config: FilterByLabels) => () => (source: Observable<DataFrame[]>) =>
  source.pipe(
    map((data: DataFrame[]) =>
      data.filter((frame) => (frame.refId === undefined ? true : filterFrameByLabels(frame, config[frame.refId])))
    )
  );

export function filterFrameByLabels(frame: DataFrame, labels: LabelInput[] | undefined): boolean {
  if (labels === undefined) {
    return true;
  }
  return (
    frame.fields.find(
      // If the frame field has labels, then we want to check for the presence of target labels.
      (x) =>
        x.labels !== undefined &&
        // Loop through the target labels and check if they exist in the frame field.
        labels.every(({ label }) => {
          const val = x.labels![label.name];
          const exists = val !== undefined;
          // Check for existence of the label first, so we can early exit if not, and
          // avoid unnecessarily compiling the labels regex.
          if (!exists) {
            // If the label doesn't exist, we want to keep this frame.
            return true;
          }
          // If the label does exist and matches the value, then we want to keep this frame.
          const lm = new LabelMatcher(label);
          return lm.matches(val);
        })
    ) !== undefined
  );
}

/**
 * LabelMatcher is a JavaScript equivalent of the `labels.Matcher` type from Prometheus' Go library.
 * It can be used to determine whether a value should be considered a 'match' for a Prometheus matcher.
 *
 * Note that this just uses the JavaScript regex engine so there may be some subtle differences between
 * this and the Prometheus implementation, but it's unlikely to appear much in day-to-day usage of Sift.
 */
class LabelMatcher {
  private value: string;
  private type: MatchType | undefined;
  private re: RegExp | undefined;
  constructor({ value, type }: Label) {
    this.type = type;
    this.value = value;
    if (this.type === MatchType.Regexp || this.type === MatchType.NotRegexp) {
      this.re = new RegExp(value);
    }
  }

  /**
   * matches returns whether the matcher matches the given string value.
   */
  public matches(val: string): boolean {
    switch (this.type) {
      case undefined:
      case MatchType.Equal:
        return this.value === val;
      case MatchType.NotEqual:
        return this.value !== val;
      case MatchType.Regexp:
        return this.re!.test(val);
      case MatchType.NotRegexp:
        return !this.re!.test(val);
    }
  }
}
