import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { css } from '@emotion/css';

const size = {
  base: '14px',
  xs: '10px',
  sm: '12px',
  md: '14px',
  lg: '18px',
};

// Custom colors that we are using in our plugin
const colors = {
  black: '#000000',
  white: '#ffffff',
  red100: '#ffc7d0',
  red200: '#ffabb8',
  red300: '#ff8fa1',
  red400: '#FF7389',
  red500: '#ff5670',
  red600: '#b35160',
  red700: '#803a45',
  green100: '#afcfa9',
  green200: '#6bca5a',
  green300: '#21ce00',
  green400: '#388729',
  green500: '#2d6c21',
  green600: '#275f1d',
  green700: '#1c4415',
  yellow100: '#fdf7a4',
  yellow200: '#fbf477',
  yellow400: '#F9EC1C',
  yellow600: '#aea514',
  yellow700: '#7d760e',
  blue100: '#a7bddf',
  blue200: '#7c9dcf',
  blue400: '#245baf',
  blue600: '#19407a',
  blue700: '#122e58',
  gray100: '#e1e3e4',
  gray200: '#d3d4d7',
  gray400: '#b5b8bc',
  gray600: '#7f8184',
  gray700: '#484a4b',
  gray800: '#3c3c3c',
};

// These values are larger than what we have available in Grafana Core, but they are on the same grid.
const spacing2Xl = '40px';
const spacing3Xl = '48px';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUtilityStyles = () => {
  const styles = useStyles2((theme?: GrafanaTheme2) => {
    const isDark = Boolean(theme?.isDark);

    /* prettier-ignore */
    return {
      // Colors
      // --------------------------
      colorWhite: css`color: ${colors.white};`,
      colorBlack: css`color: ${colors.black};`,
      colorRed: css`color: ${colors.red400};`,
      colorRed100: css`color: ${colors.red100};`,
      colorRed200: css`color: ${colors.red200};`,
      colorRed300: css`color: ${colors.red300};`,
      colorRed400: css`color: ${colors.red400};`,
      colorRed500: css`color: ${colors.red500};`,
      colorRed600: css`color: ${colors.red600};`,
      colorRed700: css`color: ${colors.red700};`,
      colorGreen: css`color: ${colors.green400};`,
      colorGreen100: css`color: ${colors.green100};`,
      colorGreen200: css`color: ${colors.green200};`,
      colorGreen300: css`color: ${colors.green300};`,
      colorGreen400: css`color: ${colors.green400};`,
      colorGreen500: css`color: ${colors.green500};`,
      colorGreen600: css`color: ${colors.green600};`,
      colorGreen700: css`color: ${colors.green700};`,
      colorYellow: css`color: ${colors.yellow400};`,
      colorYellow100: css`color: ${colors.yellow100};`,
      colorYellow200: css`color: ${colors.yellow200};`,
      colorYellow400: css`color: ${colors.yellow400};`,
      colorYellow600: css`color: ${colors.yellow600};`,
      colorYellow700: css`color: ${colors.yellow700};`,
      colorBlue: css`color: ${colors.blue400};`,
      colorBlue100: css`color: ${colors.blue100};`,
      colorBlue200: css`color: ${colors.blue200};`,
      colorBlue400: css`color: ${colors.blue400};`,
      colorBlue600: css`color: ${colors.blue600};`,
      colorBlue700: css`color: ${colors.blue700};`,
      colorGray: css`color: ${colors.gray400};`,
      colorGray100: css`color: ${colors.gray100};`,
      colorGray200: css`color: ${colors.gray200};`,
      colorGray400: css`color: ${colors.gray400};`,
      colorGray600: css`color: ${colors.gray600};`,
      colorGray700: css`color: ${colors.gray700};`,
      colorGray800: css`color: ${colors.gray800};`,
      colorVeryWeak: css`color: ${isDark ? colors.gray800 : colors.gray100};`,
      colorWeak: css`color: ${theme?.colors.text.secondary};`,
      colorSemiWeak: css`color: ${theme?.colors.text.secondary};`,
      colorStrong: css`color: ${theme?.colors.text.maxContrast};`,
      colorFormDescription: css`color: ${theme?.colors.text.secondary};`,
      colorFormInvalid: css`color: ${theme?.colors.error.border};`,

      // Background colors
      // --------------------------
      bgRed: css`background-color: ${colors.red400};`,
      bgRed100: css`background-color: ${colors.red100};`,
      bgRed200: css`background-color: ${colors.red200};`,
      bgRed400: css`background-color: ${colors.red400};`,
      bgRed600: css`background-color: ${colors.red600};`,
      bgRed700: css`background-color: ${colors.red700};`,
      bgGreen: css`background-color: ${colors.green400};`,
      bgGreen100: css`background-color: ${colors.green100};`,
      bgGreen200: css`background-color: ${colors.green200};`,
      bgGreen400: css`background-color: ${colors.green400};`,
      bgGreen600: css`background-color: ${colors.green600};`,
      bgGreen700: css`background-color: ${colors.green700};`,
      bgYellow: css`background-color: ${colors.yellow400};`,
      bgYellow100: css`background-color: ${colors.yellow100};`,
      bgYellow200: css`background-color: ${colors.yellow200};`,
      bgYellow400: css`background-color: ${colors.yellow400};`,
      bgYellow600: css`background-color: ${colors.yellow600};`,
      bgYellow700: css`background-color: ${colors.yellow700};`,
      bgBlue: css`background-color: ${colors.blue400};`,
      bgBlue100: css`background-color: ${colors.blue100};`,
      bgBlue200: css`background-color: ${colors.blue200};`,
      bgBlue400: css`background-color: ${colors.blue400};`,
      bgBlue600: css`background-color: ${colors.blue600};`,
      bgBlue700: css`background-color: ${colors.blue700};`,
      bgGray: css`background-color: ${colors.gray400};`,
      bgGray100: css`background-color: ${colors.gray100};`,
      bgGray200: css`background-color: ${colors.gray200};`,
      bgGray400: css`background-color: ${colors.gray400};`,
      bgGray600: css`background-color: ${colors.gray600};`,
      bgGray700: css`background-color: ${colors.gray700};`,
      bgGray800: css`background-color: ${colors.gray800};`,
      // The smallest the level is the less it differs from the general theme background (dark/light)
      bgLevel1: css`background-color: ${theme?.colors.background.primary};`,
      bgLevel2: css`background-color: ${theme?.colors.background.secondary};`,
      bgLevel3: css`background-color: ${theme?.colors.action.hover};`,

      // Background gradients
      // --------------------------
      gradientRed: css`background: linear-gradient(180deg, ${colors.red300} 0%, ${colors.red500} 100%);`,
      gradientGreen: css`background: linear-gradient(270deg, ${colors.green300} 0%, ${colors.green100} 100%);`,
      gradientYellow: css`background: linear-gradient(180deg, ${colors.yellow200} 0%, ${colors.yellow600} 100%);`,
      gradientBlue: css`background: linear-gradient(180deg, ${colors.blue100} 0%, ${colors.blue200} 100%);`,

      // Text
      // --------------------------
      textBold: css`font-weight: ${theme?.typography.fontWeightBold};`,
      textSemiBold: css`font-weight: ${theme?.typography.fontWeightMedium};`,
      textNormal: css`font-weight: normal;`,
      textItalic: css`font-style: italic;`,
      textUnderline: css`text-decoration: underline;`,
      textMonospace: css`font-family: monospace;`,
      textXs: css`font-size: ${size.xs};`,
      textSm: css`font-size: ${size.sm};`,
      textMd: css`font-size: ${theme?.typography.fontSize};`,
      textLg: css`font-size: ${size.lg};`,
      textXl: css`font-size: 24px;`,
      textCenter: css`text-align: center;`,
      textLeft: css`text-align: left;`,
      textRight: css`text-align: right;`,

      // Line Height
      // --------------------------
      lineHeightXs: css`line-height: ${theme?.typography.bodySmall.lineHeight};`,
      lineHeightSm: css`line-height: ${theme?.typography.bodySmall.lineHeight};`,
      lineHeightMd: css`line-height: ${theme?.typography.body.lineHeight};`,
      lineHeightLg: css`line-height: ${theme?.typography.h2.lineHeight};`,

      // Padding
      // --------------------------
      paddingNone: css`padding: 0;`,
      paddingXs: css`padding: ${theme?.spacing(0.5)};`,
      paddingSm: css`padding: ${theme?.spacing(1)};`,
      paddingMd: css`padding: ${theme?.spacing(2)};`,
      paddingLg: css`padding: ${theme?.spacing(3)};`,
      paddingXl: css`padding: ${theme?.spacing(4)};`,
      paddingVerticalXs: css`padding-top: ${theme?.spacing(0.5)};padding-bottom: ${theme?.spacing(0.5)};`,
      paddingVerticalSm: css`padding-top: ${theme?.spacing(1)};padding-bottom: ${theme?.spacing(1)};`,
      paddingVerticalMd: css`padding-top: ${theme?.spacing(2)};padding-bottom: ${theme?.spacing(2)};`,
      paddingVerticalLg: css`padding-top: ${theme?.spacing(3)};padding-bottom: ${theme?.spacing(3)};`,
      paddingVerticalXl: css`padding-top: ${theme?.spacing(4)};padding-bottom: ${theme?.spacing(4)};`,
      paddingHorizontalXs: css`padding-left: ${theme?.spacing(0.5)};padding-right: ${theme?.spacing(0.5)};`,
      paddingHorizontalSm: css`padding-left: ${theme?.spacing(1)};padding-right: ${theme?.spacing(1)};`,
      paddingHorizontalMd: css`padding-left: ${theme?.spacing(2)};padding-right: ${theme?.spacing(2)};`,
      paddingHorizontalLg: css`padding-left: ${theme?.spacing(3)};padding-right: ${theme?.spacing(3)};`,
      paddingHorizontalXl: css`padding-left: ${theme?.spacing(4)};padding-right: ${theme?.spacing(4)};`,

      // Margin
      // --------------------------
      marginNone: css`margin: 0 !important;`,
      marginAuto: css`margin: auto auto;`,
      marginXAuto: css`margin: 0 auto;`,
      marginYAuto: css`margin: auto 0;`,
      margin: css`margin: ${theme?.spacing(2)};`,
      marginXs: css`margin: ${theme?.spacing(0.5)};`,
      marginSm: css`margin: ${theme?.spacing(1)};`,
      marginMd: css`margin: ${theme?.spacing(2)};`,
      marginLg: css`margin: ${theme?.spacing(3)};`,
      marginXl: css`margin: ${theme?.spacing(4)};`,
      margin2Xl: css`margin: ${spacing2Xl};`,
      margin3Xl: css`margin: ${spacing3Xl};`,
      marginTop: css`margin-top: ${theme?.spacing(2)};`,
      marginTopXs: css`margin-top: ${theme?.spacing(0.5)};`,
      marginTopSm: css`margin-top: ${theme?.spacing(1)};`,
      marginTopMd: css`margin-top: ${theme?.spacing(2)};`,
      marginTopLg: css`margin-top: ${theme?.spacing(3)};`,
      marginTopXl: css`margin-top: ${theme?.spacing(4)};`,
      marginTop2Xl: css`margin-top: ${spacing2Xl};`,
      marginTop3Xl: css`margin-top: ${spacing3Xl};`,
      marginTopNone: css`margin-top: 0 !important;`,
      marginBottom: css`margin-bottom: ${theme?.spacing(2)};`,
      marginBottomXs: css`margin-bottom: ${theme?.spacing(0.5)};`,
      marginBottomSm: css`margin-bottom: ${theme?.spacing(1)};`,
      marginBottomMd: css`margin-bottom: ${theme?.spacing(2)};`,
      marginBottomLg: css`margin-bottom: ${theme?.spacing(3)};`,
      marginBottomXl: css`margin-bottom: ${theme?.spacing(4)};`,
      marginBottom2Xl: css`margin-bottom: ${spacing2Xl};`,
      marginBottom3Xl: css`margin-bottom: ${spacing3Xl};`,
      marginBottomNone: css`margin-bottom: 0 !important;`,
      marginLeft: css`margin-left: ${theme?.spacing(2)};`,
      marginLeftXs: css`margin-left: ${theme?.spacing(0.5)};`,
      marginLeftSm: css`margin-left: ${theme?.spacing(1)};`,
      marginLeftMd: css`margin-left: ${theme?.spacing(2)};`,
      marginLeftLg: css`margin-left: ${theme?.spacing(3)};`,
      marginLeftXl: css`margin-left: ${theme?.spacing(4)};`,
      marginLeft2Xl: css`margin-left: ${spacing2Xl};`,
      marginLeft3Xl: css`margin-left: ${spacing3Xl};`,
      marginLeftNone: css`margin-left: 0 !important;`,
      marginRight: css`margin-right: ${theme?.spacing(2)};`,
      marginRightXs: css`margin-right: ${theme?.spacing(0.5)};`,
      marginRightSm: css`margin-right: ${theme?.spacing(1)};`,
      marginRightMd: css`margin-right: ${theme?.spacing(1)};`,
      marginRightLg: css`margin-right: ${theme?.spacing(3)};`,
      marginRightXl: css`margin-right: ${theme?.spacing(4)};`,
      marginRight2Xl: css`margin-right: ${spacing2Xl};`,
      marginRight3Xl: css`margin-right: ${spacing3Xl};`,
      marginRightNone: css`margin-right: 0 !important;`,

      // Flex
      // --------------------------
      flex: css`display: flex;`,
      flex1: css`flex: 1;`,
      flexRow: css`flex-direction: row;`,
      flexColumn: css`flex-direction: column;`,
      alignCenter: css`align-items: center;`,
      alignStart: css`align-items: flex-start;`,
      alignEnd: css`align-items: flex-end;`,
      justifyCenter: css`justify-content: center;`,
      justifyStart: css`justify-content: flex-start;`,
      justifyEnd: css`justify-content: flex-end;`,
      justifySpaceBetween: css`justify-content: space-between;`,
      spaceBetween: css`align-content: space-between;`,

      // Layout
      // --------------------------
      widthFull: css`width: 100%;`,
      width100: css`width: 100px;`,
      width200: css`width: 200px;`,
      width300: css`width: 300px;`,
      width400: css`width: 400px;`,
      width500: css`width: 500px;`,
      heightFull: css`height: 100%;`,
      overflowScroll: css`overflow: scroll;`,
      overflowHidden: css`overflow: hidden;`,
      wrap: css`overflow-wrap: anywhere;`,
      absolute: css`position: absolute;`,
      relative: css`position: relative;`,
      fixed: css`position: fixed;`,
      static: css`position: static;`,
      top0: css`top: 0;`,
      bottom0: css`bottom: 0;`,
      left0: css`left: 0;`,
      right0: css`right: 0;`,

      // Borders
      // --------------------------
      borderSolid: css`border-style: solid;`,
      borderDashed: css`border-style: dashed;`,
      border: css`border-width: 1px;`,
      borderNone: css`border-width: 0;`,
      borderTop: css`border-width: 1px 0 0 0;`,
      borderBottom: css`border-width: 0 0 1px 0;`,
      borderLeft: css`border-width: 0 0 0 1px;`,
      borderRight: css`border-width: 0 1px 0 0;`,
      borderRed: css`border-color: ${colors.red400};`,
      borderGreen: css`border-color: ${colors.green400};`,
      borderBlue: css`border-color: ${colors.blue400};`,
      borderYellow: css`border-color: ${colors.yellow400};`,
      borderGray: css`border-color: ${colors.gray400};`,
      borderGray200: css`border-color: ${colors.gray200};`,
      borderGray600: css`border-color: ${colors.gray600};`,
      borderStrong: css`border-color: ${theme?.colors.text.maxContrast};`,
      borderWeak: css`border-color: ${theme?.colors.text.secondary};`,
      borderSemiWeak: css`border-color: ${theme?.colors.text.secondary};`,
      borderVeryWeak: css`border-color: ${isDark ? colors.gray800 : colors.gray100};`,
      borderCurrentColor: css`border-color: currentColor;`,

      // Utility
      // --------------------------
      rounded: css`border-radius: 3px;`,
      roundedLg: css`border-radius: 8px;`,
      roundedFull: css`border-radius: 100%;`,
      displayNone: css`display: none;`,
      block: css`display: block;`,
      inline: css`display: inline;`,
      inlineBlock: css`display: inline-block;`,
      pointer: css`cursor: pointer;`,
      outlineNone: css`outline: none;`,
      noFocusOutline: css`&:focus { outline: none; outline-offset: 0; box-shadow: none; }`,
      noTransition: css`transition: none !important;`,
      resizeNone: css`resize: none;`,

      // Theme Specific
      themeErrorBg: css`background-color: ${theme?.colors.error.main};`,
      themeErrorColor: css`color: ${theme?.colors.error.contrastText};`,
      themeErrorBorder: css`border-color: ${isDark? colors.red400 : colors.red600};`,
    };
  });

  return styles;
};
