import { dateTimeFormat, DateTimeInput, getTimeZone } from '@grafana/data';
import { TimeZone } from '@grafana/schema';

import { isEmpty } from 'lodash';

export function getTimeZoneWithDefault(timeZone?: TimeZone): TimeZone {
  if (!timeZone || isEmpty(timeZone)) {
    return getTimeZone();
  }
  return timeZone;
}

export function formatDateTime(input: DateTimeInput): string {
  return dateTimeFormat(input, { timeZone: getTimeZone() });
}
