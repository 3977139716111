import { gte } from 'semver';

export const MAX_NAME_LENGTH = 63;

/**
 * Generates a "name" from any string that only contains characters accepted by the backend.
 * (we generally use the "name" properties as IDs throughout the app)
 */
export const generateName = (displayName: string): string => {
  return displayName
    .toLowerCase()
    .trim()
    .replace(/\s\s+/g, ' ')
    .replace(/[^a-z0-9.-]/g, '-')
    .slice(0, MAX_NAME_LENGTH);
};

export function neverCase(x: never): never {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  throw new Error(`Non-exhaustive: case ${x} was not handled.`);
}

/**
 * Wrapper around semver.gte that returns `true` if parsing either argument fails,
 * rather than throwing.
 *
 * The justification for returning `true` instead of `false` is that there has _probably_
 * been an unexpected update to Grafana which has caused it to return invalid version
 * strings for some reason. This automatically means that the running version is newer
 * than anything we've seen before, hence we return `true` indicating that v1 (usually
 * the version of Grafana from config, in this plugin's usage) is greater than v2
 * (usually some statically declared string, used to workaround API differences in this
 * plugin).
 */
export function tryGte(v1: string, v2: string): boolean {
  try {
    return gte(v1, v2);
  } catch {
    return true;
  }
}
