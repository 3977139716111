import { createContext, useContext } from 'react';

import { AppPluginMeta } from '@grafana/data';

import { MlAppSettings } from 'types';

export const PluginMetaContext = createContext<AppPluginMeta<MlAppSettings> | null>(null);

export function usePluginMeta(): AppPluginMeta<MlAppSettings> | null {
  return useContext(PluginMetaContext);
}
