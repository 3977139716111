import { FieldType, PanelData } from '@grafana/data';

import { OutlierField } from 'api/types';

export interface SparkRange {
  min: number | null;
  max: number | null;
}

const COMPARE_TYPE = {
  MIN: 'min',
  MAX: 'max',
};

// takes an original value and a current value and returns the one that
// is either larger or smaller (depends on compareType) and handles nulls
const getNextValue = (compareType: string, original: number | null, current: number | null): number | null => {
  // if the original value is not set, use whatever current is
  if (original === null) {
    return current;
  }

  // if the current value is not set, keep the original
  if (current === null) {
    return original;
  }

  // if we want to return the min value
  if (compareType === COMPARE_TYPE.MIN) {
    return current < original ? current : original;
  }

  // otherwise return the max value
  return current > original ? current : original;
};

// determine the min and max scale for a single series based
// on the overall scale of the dataset
export const getSparkRange = (alignedData: PanelData | undefined): SparkRange =>
  alignedData?.series?.[0] !== undefined
    ? alignedData.series[0].fields.reduce(
        (settings: SparkRange, field: OutlierField<number>) => {
          if (field.type === FieldType.time) {
            return { min: null, max: null };
          }

          const cleanedBuffer = field.values.filter(Number.isFinite);
          const minMax = cleanedBuffer.reduce<SparkRange>(
            (limits: SparkRange, val: number) => {
              return {
                min: limits.min === null || val < limits.min ? val : limits.min,
                max: limits.max === null || val > limits.max ? val : limits.max,
              };
            },
            { min: null, max: null }
          );
          return {
            min: getNextValue(COMPARE_TYPE.MIN, settings.min, minMax.min),
            max: getNextValue(COMPARE_TYPE.MAX, settings.max, minMax.max),
          };
        },
        { min: null, max: null }
      )
    : { min: 0, max: 0 };
