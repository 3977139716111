import {
  IconName,
  PanelData,
  PluginExtensionComponentConfig,
  PluginExtensionLinkConfig,
  PluginExtensionPanelContext,
  RawTimeRange,
  TimeRange,
} from '@grafana/data';
import { DataQuery, TimeZone } from '@grafana/schema';

import { AlertInstance, Label, OnCallAlertGroup } from 'types';

export type ExtensionComponentConfig = Omit<PluginExtensionComponentConfig, 'type'>;
export type ExtensionLinkConfig = Omit<PluginExtensionLinkConfig<object>, 'type'>;
export type ExtensionLinkParams = Omit<ExtensionLinkConfig, 'title' | 'description' | 'icon' | 'category'> & {
  title?: string;
  description?: string;
  icon?: IconName;
  category?: string;
};

export enum ExtensionPointIds {
  DashboardPanelMenu = 'grafana/dashboard/panel/menu',
  ExploreToolbarAction = 'grafana/explore/toolbar/action',
}

// NOTE: this is an unfortunate workaround to enable our plugin to be backward-compatible
// with versions of Grafana < 9.5.0. Once we drop support for older versions, we can
// remove this and import it directly from @grafana/data.
export enum PluginExtensionPoints {
  DashboardPanelMenu = 'grafana/dashboard/panel/menu',
  ExploreToolbarAction = 'grafana/explore/toolbar/action',
}
// These extension points are in Grafana but not in a released
// version of @grafana/data, so we define them here for now.
export enum NewPluginExtensionPoints {
  AlertInstanceAction = 'grafana/alerting/instance/action',
  CommandPalette = 'grafana/commandpalette/action',
  OnCallAlertGroupAction = 'plugins/grafana-oncall-app/alert-group/action',
  IncidentRunSiftModal = 'plugins/grafana-incident-app/run-sift-modal',
  IncidentAnalysisModal = 'plugins/grafana-incident-app/sift-analysis-modal',
  K8sClusterAction = 'plugins/grafana-k8s-app/cluster/action',
  K8sNamespaceAction = 'plugins/grafana-k8s-app/namespace/action',
  K8sWorkloadAction = 'plugins/grafana-k8s-app/workload/action',
  K8sPodAction = 'plugins/grafana-k8s-app/pod/action',
  SiftPanelRunInvestigation = 'plugins/grafana-sift-panel/run-investigation',
  AppO11yServiceAction = 'plugins/grafana-app-observability-app/service/action',
  AppO11yOperationAction = 'plugins/grafana-app-observability-app/operation/action',
}

export type AnyExtensionPoint = NewPluginExtensionPoints | PluginExtensionPoints;

export interface ExtensionParams {
  title: string;
  description: string;
  category?: string;
  icon?: IconName;
}

export interface PluginExtensionPanelContextWrapper {
  context?: PluginExtensionPanelContext;
  type: PluginExtensionPoints.DashboardPanelMenu;
}

// The type of the context passed to a plugin extension from the Explore page.
// This type is not yet available in @grafana/data, so we define it here.
export type PluginExtensionExploreContext = {
  exploreId: string;
  targets: DataQuery[];
  data: PanelData;
  timeRange: RawTimeRange;
  timeZone: TimeZone;
};
export interface PluginExtensionExploreContextWrapper {
  context?: PluginExtensionExploreContext;
  type: PluginExtensionPoints.ExploreToolbarAction;
}

// The type of the context passed to a plugin extension from an alert instance.
// This type is not yet available in @grafana/data, so we define it here.
export type PluginExtensionAlertInstanceContext = {
  instance: AlertInstance;
};
export interface PluginExtensionAlertInstanceContextWrapper {
  context?: PluginExtensionAlertInstanceContext;
  type: NewPluginExtensionPoints.AlertInstanceAction;
}

// The type of context passed to a plugin extension from the command palette.
// This is effectively empty since there's no context to pass.
export type PluginExtensionCommandPaletteContext = {};
export interface PluginExtensionCommandPaletteContextWrapper {
  context?: PluginExtensionCommandPaletteContext;
  type: NewPluginExtensionPoints.CommandPalette;
}

// The type of the context passed to a plugin extension from an alert instance.
// This type is defined in the OnCall plugin so we'll need to copy it here since
// we can't import it.
export type PluginExtensionOnCallAlertGroupContext = {
  alertGroup: OnCallAlertGroup;
};
export interface PluginExtensionOnCallAlertGroupContextWrapper {
  context?: PluginExtensionOnCallAlertGroupContext;
  type: NewPluginExtensionPoints.OnCallAlertGroupAction;
}

export interface PluginExtensionIncidentRunSiftContextWrapper {
  type: NewPluginExtensionPoints.IncidentRunSiftModal;
}

// Types for the K8s plugin extensions.
interface K8sDatasource {
  name: string;
  uid: string;
}
interface K8sDatasources {
  prometheus: K8sDatasource;
  loki: K8sDatasource;
}
export interface PluginExtensionK8sClusterContext {
  timeRange: RawTimeRange;
  cluster: string;
  datasources?: K8sDatasources;
}
export interface PluginExtensionK8sClusterContextWrapper {
  type: NewPluginExtensionPoints.K8sClusterAction;
  context?: PluginExtensionK8sClusterContext;
}
export interface PluginExtensionK8sNamespaceContext {
  timeRange: RawTimeRange;
  cluster: string;
  namespace: string;
  datasources?: K8sDatasources;
}
export interface PluginExtensionK8sNamespaceContextWrapper {
  type: NewPluginExtensionPoints.K8sNamespaceAction;
  context?: PluginExtensionK8sNamespaceContext;
}
export type WorkloadTypes = '' | 'replicaset' | 'deployment' | 'daemonset' | 'statefulset' | 'job';
export interface PluginExtensionK8sWorkloadContext {
  timeRange: RawTimeRange;
  cluster: string;
  namespace: string;
  workload: string;
  workloadType: WorkloadTypes;
  datasources?: K8sDatasources;
}
export interface PluginExtensionK8sWorkloadContextWrapper {
  type: NewPluginExtensionPoints.K8sWorkloadAction;
  context?: PluginExtensionK8sWorkloadContext;
}
export interface PluginExtensionK8sPodContext {
  timeRange: RawTimeRange;
  cluster: string;
  namespace: string;
  workload: string;
  workloadType?: string;
  pod: string;
  datasources?: K8sDatasources;
}
export interface PluginExtensionK8sPodContextWrapper {
  type: NewPluginExtensionPoints.K8sPodAction;
  context?: PluginExtensionK8sPodContext;
}

// Types for the Sift panel.
export interface PluginExtensionSiftPanelContext {
  timeRange: TimeRange;
  labels: Label[];
}
export interface PluginExtensionSiftPanelContextWrapper {
  type: NewPluginExtensionPoints.SiftPanelRunInvestigation;
  context?: PluginExtensionSiftPanelContext;
}

export interface PluginExtensionAppO11yServiceContext {
  timeRange: RawTimeRange;
  datasources: {
    prometheus?: { name: string };
    loki?: { name: string };
    tempo?: { name: string };
  };
  service: {
    // The service name.
    name: string;
    // The service namespace.
    namespace: string;
    // The job, i.e. the combination of service namespace and name.
    job: string;
  };
  k8s?: Array<Record<string, string>>;
  cloud?: Array<Record<string, string>>;
}
export interface PluginExtensionAppO11yServiceContextWrapper {
  type: NewPluginExtensionPoints.AppO11yServiceAction;
  context?: PluginExtensionAppO11yServiceContext;
}
export type PluginExtensionAppO11yOperationContext = PluginExtensionAppO11yServiceContext & {
  operation: string;
};
export interface PluginExtensionAppO11yOperationContextWrapper {
  type: NewPluginExtensionPoints.AppO11yOperationAction;
  context?: PluginExtensionAppO11yOperationContext;
}

export type PluginExtensionContextWrapper =
  | PluginExtensionPanelContextWrapper
  | PluginExtensionAlertInstanceContextWrapper
  | PluginExtensionCommandPaletteContextWrapper
  | PluginExtensionExploreContextWrapper
  | PluginExtensionOnCallAlertGroupContextWrapper
  | PluginExtensionIncidentRunSiftContextWrapper
  | PluginExtensionK8sClusterContextWrapper
  | PluginExtensionK8sNamespaceContextWrapper
  | PluginExtensionK8sWorkloadContextWrapper
  | PluginExtensionK8sPodContextWrapper
  | PluginExtensionSiftPanelContextWrapper
  | PluginExtensionAppO11yServiceContextWrapper
  | PluginExtensionAppO11yOperationContextWrapper;

// Convenience type for plugin link config with some kind of context.
export type PluginLinkConfig<Context extends object> = Omit<PluginExtensionLinkConfig<Context>, 'type'>;
// Type for plugin link config with Panel context. Associates the correct extensionPointId for type safety.
export interface AlertInstancePluginLinkConfig extends PluginLinkConfig<PluginExtensionAlertInstanceContext> {
  extensionPointId: NewPluginExtensionPoints.AlertInstanceAction;
}
// Type for plugin link config with CommandPalette context. Associates the correct extensionPointId for type safety.
export interface CommandPalettePluginLinkConfig extends PluginLinkConfig<PluginExtensionCommandPaletteContext> {
  extensionPointId: NewPluginExtensionPoints.CommandPalette;
}
// Type for plugin link config with Explore context. Associates the correct extensionPointId for type safety.
export interface ExplorePluginLinkConfig extends PluginLinkConfig<PluginExtensionExploreContext> {
  extensionPointId: PluginExtensionPoints.ExploreToolbarAction;
}
// Type for plugin link config with OnCall context. Associates the correct extensionPointId for type safety.
export interface OnCallPluginLinkConfig extends PluginLinkConfig<PluginExtensionOnCallAlertGroupContext> {
  extensionPointId: NewPluginExtensionPoints.OnCallAlertGroupAction;
}
// Type for plugin link config with Panel context. Associates the correct extensionPointId for type safety.
export interface PanelPluginLinkConfig extends PluginLinkConfig<PluginExtensionPanelContext> {
  extensionPointId: PluginExtensionPoints.DashboardPanelMenu;
}
export interface IncidentRunSiftLinkConfig extends PluginLinkConfig<PluginExtensionPanelContext> {
  extensionPointId: NewPluginExtensionPoints.IncidentRunSiftModal;
}
export interface K8sClusterPluginLinkConfig extends PluginLinkConfig<PluginExtensionK8sClusterContext> {
  extensionPointId: NewPluginExtensionPoints.K8sClusterAction;
}
export interface K8sNamespacePluginLinkConfig extends PluginLinkConfig<PluginExtensionK8sNamespaceContext> {
  extensionPointId: NewPluginExtensionPoints.K8sNamespaceAction;
}
export interface K8sWorkloadPluginLinkConfig extends PluginLinkConfig<PluginExtensionK8sWorkloadContext> {
  extensionPointId: NewPluginExtensionPoints.K8sWorkloadAction;
}
export interface K8sPodPluginLinkConfig extends PluginLinkConfig<PluginExtensionK8sPodContext> {
  extensionPointId: NewPluginExtensionPoints.K8sPodAction;
}
export interface SiftPanelPluginLinkConfig extends PluginLinkConfig<PluginExtensionSiftPanelContext> {
  extensionPointId: NewPluginExtensionPoints.SiftPanelRunInvestigation;
}
export interface AppO11yServicePluginLinkConfig extends PluginLinkConfig<PluginExtensionAppO11yServiceContext> {
  extensionPointId: NewPluginExtensionPoints.AppO11yServiceAction;
}
export interface AppO11yOperationPluginLinkConfig extends PluginLinkConfig<PluginExtensionAppO11yOperationContext> {
  extensionPointId: NewPluginExtensionPoints.AppO11yOperationAction;
}
