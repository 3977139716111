import { getDataSourceSrv } from '@grafana/runtime';
import { DataQuery } from '@grafana/schema';

import { DATASOURCE_NAME, SUPPORTED_DATASOURCE_TYPES } from 'consts';

import { isExploreContext, isPanelContext } from './guards';
import { ExtensionLinkConfig } from './types';

/**
 * Returning undefined will hide the extension.
 * Returning an object will use the provided properties to override the values of the extension.
 */

export function configurePanelLink(context: object | undefined): Partial<ExtensionLinkConfig> | undefined {
  if (!isPanelContext(context)) {
    return undefined;
  }

  if (context.pluginId !== 'timeseries' && context.pluginId !== 'graph') {
    return undefined;
  }

  const [query] = context?.targets ?? [];
  return configureQueryLink(query);
}

export function configureExploreLink(context: object | undefined): Partial<ExtensionLinkConfig> | undefined {
  if (!isExploreContext(context)) {
    return undefined;
  }

  const [query] = context?.targets ?? [];
  return configureQueryLink(query);
}

function configureQueryLink(query: DataQuery | undefined): Partial<ExtensionLinkConfig> | undefined {
  if (!query || !query.datasource?.uid) {
    return undefined;
  }

  const settings = getDataSourceSrv().getInstanceSettings(query.datasource);

  if (!settings) {
    return undefined;
  }

  if (!SUPPORTED_DATASOURCE_TYPES.includes(settings.type)) {
    return undefined;
  }

  if (settings.name === DATASOURCE_NAME) {
    return undefined;
  }

  return {};
}
